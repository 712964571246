/*
    This css file is specifically for stylings that should not change when the page theme is changed
    Examples: positioning, sizing, spacing
*/
body {
    margin: 0;
}

input {
    box-sizing: border-box;
}

option[value=""][disabled] {
    color: gray;
}

option {
    color: black;
}

select:required:invalid {
    color: gray;
}

.app {
    font-size: calc(10px + 2vmin);
    flex-direction: column;
    display: flex;
}

.footer {
    display: flex;
    flex-direction: column;
}

.footer--dark-text {
    font-size: 21px;
    text-align: left;
    padding-bottom: 2vh;
}

.footer--dark-text-header {
    font-size: 27px;
    font-weight: bold;
    padding-bottom: 2vh;
}

.footer--expectation-panel {
    text-align: left;
    padding: 30px;
    padding-left: max(calc(45vw - 490px), 30px);
    padding-right: max(calc(45vw - 490px), 30px);
}

.footer--light-text-header {
    font-size: 27px;
    font-weight: bold;
}

.footer--list {
    margin-left: 10px;
    margin-bottom: 15px;
    counter-reset: counter;
    list-style: none;
    padding-left: 40px;
}

.footer--list-header {
    text-align: left;
    margin-left: 10px;
    font-weight: bold;
    font-size: 25px;
}

.footer--list-item {
    margin: 25px 0.5rem 0;
    counter-increment: counter;
    position: relative;
}

.footer--list-item::before {
    content: counter(counter);
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    --size: 32px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 10;
    border-radius: 50%;
    text-align: center;
}

.footer--list-text {
    font-size: 21px;
    padding-left: 10px;
}

.footer--preparation-panel {
    text-align: left;
    padding: 30px;
    padding-left: max(calc(45vw - 490px), 30px);
    padding-right: max(calc(45vw - 490px), 30px);
}

.generate-button {
    border-width: 0px;
    border-radius: 4px;
    padding-right: 10px;
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    min-height: 2.5em;
}

.generate-button--disabled {
    border-width: 0px;
    border-radius: 4px;
    padding-right: 10px;
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    min-height: 2.5em;
}

.header {
    height: 108px;
    margin: 0px;
    display: block;
}

.header>img {
    margin: 10px;
    justify-content: center;
    float: left;
}

.logo {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: max(calc(45vw - 520px), 0px);
}

.menu {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    align-items: center;
    max-width: 530px;
    row-gap: 20px;
    column-gap: 30px;
}

.menu--background-image {
    display: flex;
    flex-direction: row;
}

.menu--container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    padding: 30px;
    margin-left: max(calc(45vw - 520px), 0px)
}

.menu--error-notif {
    height: 15px;
    font-size: 15px;
    padding: 0px;
    padding-top: 5px;
    margin-bottom: 5px;
    text-indent: 5px;
}

.menu--generate-codes {
    row-gap: 5px;
}

.menu--responsive {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    align-items: center;
    width: 100%;
}

.menu--text-header {
    font-size: 35px;
    display: flex;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin: 10px 0px 10px 0px;
}

.menu--item {
    width: 250px;
    height: 33px;
    border-radius: 4px;
    padding: 0px;
    margin: 0px;
    text-indent: 10px;
    border: none;
}

.menu--item-container {
    margin: 0px;
    display: flex;
    flex-direction: column;
}

.menu--item-container-hidden {
    display: none;
}

.policy--checkbox {
    margin-left: 1em;
    margin-right: 1em;
}

.policy--container {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 15px;
    background-color: rgba(201, 201, 201, 0.6);
    border-radius: 4px;
    width: fit-content;
    float: right;
    padding-right: 5px;
    padding-left: 5px;
}

.slider {
    -webkit-appearance: none;
    width: 60%;
    height: 15px;
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.start-test-div {
    width: 100%;
}

.submit-button {
    border-width: 0px;
    border-radius: 4px;
    padding-right: 10px;
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
    min-height: 2.5em;
    float: right;
}

.submit-button--disabled {
    border-width: 0px;
    border-radius: 4px;
    padding-right: 10px;
    margin: 0px;
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
    min-height: 2.5em;
    float: right;
}

@media only screen and (max-width: 1200px) {
    input[type=checkbox]
    {
        /* Double-sized Checkboxes */
        -ms-transform: scale(2);
        -moz-transform: scale(2);
        -webkit-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2);
    }
}
.landingpage-graphic {
    display: block;
    min-width:min(90vw,100px);
    justify-content: center;
    align-self: center;
}

.landingpage-container {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    text-align: center;

}

.landingpage-text-container {
    width: 80vw;
    justify-content: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    row-gap:30px;
    padding-top:40px ;
}

.landingpage-header {
    font-size: 40px;
    font-weight: 700;
    justify-content: center;
}

.landingpage-text {
    font-size: 1.8em;
    font-weight: 600;
    color: dimgray;
    text-align: center;
}